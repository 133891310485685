import classNames from 'classnames/bind';
import Header from '../../Component/Layout/Header';
import { images } from '../../assets';
import { getCookie, request } from '../../warehouse';
import { useNavigate, Link } from 'react-router-dom';
import config from '../../config';
import { Fragment, useEffect, useState } from 'react';
import styles from './Maintenance.module.scss';


const cx = classNames.bind(styles);

function Maintenance() {

    return (
        <div className={cx('wrapper')}>
            <p className={cx('first')}>
                <img className={cx('first-image')} src={images.aolang} alt="" />
            </p>
            <p className={cx('middle')}>
                Hiện ứng dụng đang trong quá trình bảo trì.
                <br />
                Hãy quay lại sau bạn nhé.
            </p>

            <a className={cx('last')} href="https://www.facebook.com/aolang.edu.vn" target="_blank">
                Ghé fanpage nhà Ao
            </a>
        </div>
    );
}

export default Maintenance;
