export const images = {
    logo: require('./images/logo.png'),
    aolang: require('./images/logo_ao.png'),
    aolangBlank: require('./images/logo_ao_blank.png'),
    aolangOpacity: require('./images/aolang_opacity.png'),
    iot: require('./images/iot.png'),
    frog: require('./images/frog.png'),
    home: require('./images/home.png'),
    marathon: require('./images/marathon.png'),
    hurdling: require('./images/hurdling.png'),
    calculaphobia: require('./images/calculaphobia.png'),
    deciphering: require('./images/deciphering.png'),
    vocab: require('./images/vocaboostery.png'),
    clicky: require('./images/clicky.png'),
    verbatim: require('./images/verbatim.png'),
    communityBig: require('./images/communityBig.png'),
    communitySmall: require('./images/communitySmall.png'),
    puzzleBig: require('./images/puzzleBig.png'),
    puzzleSmall: require('./images/puzzleSmall.png'),
    encyclopediaBig: require('./images/encyclopediaBig.png'),
    encyclopediaSmall: require('./images/encyclopediaSmall.png'),
    defaultAvatar: require('./images/user.png'),
    check: require('./images/check.png'),
    facebook: require('./images/facebook.png'),
    mail: require('./images/mail.png'),
    feedback: require('./images/feedback.png'),
    addQuestion: require('./images/addQuestion.png'),
    eyesOpen: require('./images/eyesOpen.png'),
    eyesClose: require('./images/eyesClosed.png'),
    lotus: require('./images/lotus.png'),
    teamDob: require('./images/team-dob.png'),
    teamGraduate: require('./images/team-graduate.png'),
    teamHobby: require('./images/team-hobby.png'),
    teamHome: require('./images/team-home.png'),
    teamPeak: require('./images/team-peak.png'),
    teamSchool: require('./images/team-school.png'),
    lotusLeaf: require('./images/lotusleaf.png'),
    lock: require('./images/lock.png'),
    avatarbtn: require('./images/avatarbtn.png'),
    star: require('./images/star.png')
};

export const audios = {
    tenSecond: require('./audios/10.mp3'),
    fifteenSecond: require('./audios/20.mp3'),
    twentySecond: require('./audios/30.mp3'),
    twentyFiveSecond: require('./audios/40.mp3'),
    starting: require('./audios/starting74s.mp3'),
    correct: require('./audios/right.mp3'),
    wrong: require('./audios/wrong.mp3'),
    star: require('./audios/star.mp3')
};
